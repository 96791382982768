import React from 'react';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from 'Store/store';
import Item from 'Pages/Item';
import Register from 'Pages/Register';
import RegisterSuccess from 'Pages/RegisterSuccess';
import Claim from 'Pages/Claim';
import ClaimSuccess from 'Pages/ClaimSuccess';
import SellSuccess from 'Pages/SellSuccess';
import Login from 'Pages/Login';
import Signup from 'Pages/Signup';
import Settings from 'Pages/Settings';
import Contact from 'Pages/Contact';
import ContactDeploy from 'Pages/ContactDeploy';
import MoreSecureNfts from 'Pages/Posts/MoreSecureNfts';
import PrivacyPolicy from 'Pages/PrivacyPolicy';

// save for ref this page still gets sha256 on the front end
// import CreateToken from 'Pages/Tokens/Create';
import Card from 'Pages/Tokens/Card';
import Certificate from 'Pages/Tokens/Certificate';
import TOS from 'Pages/TOS';
import ChicADeesInfoPage from 'Pages/Chicadees/Info';
import CashCowsInfoPage from 'Pages/CashCows/Info';
import JPandFriends from 'Pages/JPandFriends/Info';
import MintJPandFriends from 'Pages/JPandFriends/Mint';
// may come back
// import MintChicadee from 'Pages/Chicadees/Mint';
import ChicadeeSuccess from 'Pages/Chicadees/Success';
import JPandFriendsSuccess from 'Pages/JPandFriends/Success';
import ZombieFriends from 'Pages/ZombieFriends';
import ZombieFriendsFreeMint from 'Pages/ZombieFriendsFreeMint';

import ZombieFriendsSuccess from 'Pages/ZombieFriends/sections/Success';

import NFTStandards from 'Pages/NFTStandards';
import ScrollToTop from 'Components/ScrollToTop';
import HomePage from 'Pages/Home/index';
import GetStarted from 'Pages/GetStarted';
import FAQUpdate from 'Pages/FAQ/index';
import MintNft from 'Pages/MintNft';
import HistoryUpdate from 'Pages/History/index';
import MyCollection from 'Pages/MyCollection';
import NftDetails from 'Pages/NftDetails';
import NftLookup from 'Pages/NftLookup';
import CitizenzStaking from 'Pages/CitizenzStaking';
import MintToCollection from 'Pages/MintToCollection';
import ExternalMint from 'Pages/ExternalMint';
import BuyNFtSuccessPage from 'Pages/BuyNftSuccess';
import BuyCredits from 'Pages/BuyCredits';
import BuyCreditsSuccess from 'Pages/BuyCreditsSuccess';
import CollectionSuccess from 'Pages/CollectionSuccess';
// LootVault Pages
// Not currently using the auction / may use later still
// import AuctionListing from 'Pages/AuctionListing';
// import AuctionDetail from 'Pages/AuctionDetail';
// import Bids from 'Pages/Bids';
// import LootVaultFAQ from 'Pages/LootVaultFAQ/index';

import ApiSignup from 'Pages/ApiSignup';
import ApiPricing from 'Pages/Pricing';
import ApiUsage from 'Pages/ApiUsage';

import Auth from './Auth';
import history from './history';
import { Switch } from 'react-router';
import Collection from 'Pages/CreateCollection';

const { store } = configureStore();

const Routes = () => (
  <Provider store={store}>
    <Auth>
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <Route path="/i/:id" exact component={Item} />

            <Route path="/login" exact component={Login} />
            <Route path="/signup" exact component={Signup} />

            <Route path="/register/:code/success" exact component={RegisterSuccess} />
            <Route path="/sellSuccess/:contract/:code" exact component={SellSuccess} />

            {/* Register was for registering QR codes, next to remove, may be redundant to claim */}
            <Route path="/register/:code" exact component={Register} />

            {/* Claim is being used for claim NFTs */}
            <Route path="/claim/:marketOrderId" exact component={Claim} />
            <Route path="/claim/:marketOrderId/:success" exact component={ClaimSuccess} />

            <Route path="/faq" exact component={FAQUpdate} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/contactDeploy" exact component={ContactDeploy} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            {/* facebook needs without dash */}
            <Route path="/privacypolicy" exact component={PrivacyPolicy} />

            <Route path="/TOS" exact component={TOS} />
            <Route path="/tos" exact component={TOS} />
            <Route path="/nft-standards" exact component={NFTStandards} />

            {/* <Route path="/create-nft" exact component={CreateToken} /> */}

            {/* Mint Chic-A-Dees May Come back */}
            {/* <Route path="/chicadees/mint" exact component={MintChicadee} /> */}
            <Route path="/chicadees" exact component={ChicADeesInfoPage} />
            <Route path="/cashcows" exact component={CashCowsInfoPage} />
            <Route path="/jpandfriends" exact component={JPandFriends} />
            <Route path="/justinpierceandfriends" exact component={JPandFriends} />
            <Route path="/jpandfriends/success" exact component={JPandFriendsSuccess} />
            <Route path="/jpandfriends/mint" exact component={MintJPandFriends} />
            <Route path="/zombiefriends" exact component={ZombieFriends} />
            <Route path="/zombiefriends/mint" exact component={ExternalMint} />
            <Route path="/zombiefriends/success" exact component={ZombieFriendsSuccess} />
            <Route path="/zombiefriendssecretfreemint" exact component={ZombieFriendsFreeMint} />

            <Route path="/chicadees/success" exact component={ChicadeeSuccess} />
            <Route path="/certificate/:contractAddress/:tokenId" exact component={Certificate} />
            <Route path="/card/:contractAddress/:tokenId" exact component={Card} />

            {/* Redirect because app is dead */}
            <Route path="/post/download-app" exact component={HomePage} />
            <Route path="/post/more-secure-nfts" exact component={MoreSecureNfts} />

            <Route path="/get-started" exact component={GetStarted} />
            <Route path="/faq-update" exact component={FAQUpdate} />
            <Route path="/settings" exact component={Settings} />
            <Route path="/mint-nft" exact component={MintNft} />
            <Route path="/nft-lookup" exact component={NftLookup} />
            <Route path="/create-collection" exact component={Collection} />
            <Route path="/create-collection-success" exact component={CollectionSuccess} />
            <Route path="/history" exact component={HistoryUpdate} />
            <Route path="/my-collection" exact component={MyCollection} />
            <Route path="/citizenz" exact component={CitizenzStaking} />
            <Route path="/token/:contractAddress/:tokenId/:blockchain?" exact component={NftDetails} />
            <Route path="/nft/:tokenId" exact component={NftDetails} />
            <Route path="/citizenz-staking" exact component={CitizenzStaking} />
            <Route path="/mint-to-collection" exact component={MintToCollection} />
            {/* This is for minting from other websites */}
            <Route path="/contractmint" exact component={ExternalMint} />
            <Route path="/buy-credits" exact component={BuyCredits} />
            <Route path="/buy-credits-success" exact component={BuyCreditsSuccess} />
            <Route path="/mint-nft-success" exact component={BuyNFtSuccessPage} />

            {/* lootvault pages routes */}
            {/* <Route path="/lootvault/listings" exact component={AuctionListing} />
            <Route path="/lootvault/listing-details/:id" exact component={AuctionDetail} />
            <Route path="/lootvault/bids" exact component={Bids} />
            <Route path="/lootvault/faq" exact component={LootVaultFAQ} />
            <Route path="/lootvault" exact component={AuctionListing} /> */}

            {/* Api GUI routes */}
            <Route path="/api-signup" exact component={ApiSignup} />
            <Route path="/api-pricing" exact component={ApiPricing} />
            <Route path="/pricing" exact component={ApiPricing} />
            <Route path="/api-usage" exact component={ApiUsage} />

            {/* / needs to be last without 'exact' to catch hash tags */}
            <Route path="/" exact component={HomePage} />
          </Switch>
        </ScrollToTop>
      </Router>
    </Auth>
  </Provider>
);

export default Routes;
