import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { Box, Grid, OutlinedInput, InputAdornment, IconButton, Dialog } from '@material-ui/core';
import Container from 'Components/Container';
import Loading from 'Components/Loading';
import SectionLayout from 'Layout/SectionLayout';
import { logoutUser, setActiveModal } from 'Store/actions';
import { createNFTFromCredit, uploadFileAsync, listCollections, fetchUserCredits } from 'Services/api';
import Input from 'Shared/Input';
import Select from 'Shared/SelectField';
import Button from 'Shared/Button';
import TextArea from 'Shared/TextArea';
import { getSha256 } from 'Helpers/share/utils';
import CSelect from './CustomSelect';
import UploadBtn from './UploadBtn';

import Cros from './assets/cross.svg';
import * as Yup from 'yup';
import { useStyles } from './style';

const initialState = [
  {
    id: Date.now(),
    trait_type: 'Property',
    value: 'Value',
  },
];

const ASSET_OPTIONS = [
  {
    label: 'Single NFT',
    value: 'nft',
  },
  {
    label: 'NFT with Multiple Copies (Tickets, Coupons, etc.)',
    value: 'no-nft',
  },
];

const Mint = () => {
  const classess = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const timerRef = useRef(null);

  const history = useHistory();
  const [showCreateCollection, setShowCreateCollection] = useState(false);

  const [loading, setLoading] = useState(true);
  const [collections, setCollections] = useState([]);
  const [credits, setCredits] = useState(null);

  const [imageUrls, setImageUrls] = useState({});
  const [sha256, setSha256] = useState(null);
  const [attributes, setAttributes] = useState(initialState);

  const addAttributes = () => {
    setAttributes([...attributes, { id: Date.now(), trait_type: 'Property', value: 'Value' }]);
  };

  const removeAttributes = useCallback(
    item => {
      const data = attributes.filter(attribute => attribute.id !== item.id);
      setAttributes(data);
    },
    [attributes],
  );

  const handleTraitTypeChange = useCallback(
    (value, item) => {
      const data = attributes.map(it => {
        if (it.id === item.id) return { ...it, trait_type: value };
        return it;
      });
      setAttributes(data);
    },
    [attributes],
  );

  const isMintDisabled = () => {
    if (!imageUrls.thumb) return true;
    if (!user) return true;
    if (loading) return true;

    return false;
  };

  const handleValueChange = useCallback(
    (value, item) => {
      const data = attributes.map(it => {
        if (it.id === item.id) return { ...it, value };
        return it;
      });
      setAttributes(data);
    },
    [attributes],
  );

  const toggleLogin = useCallback(() => {
    if (user) {
      dispatch(logoutUser({ returnPath: '/' }));
    } else {
      dispatch(setActiveModal('login'));
    }
  }, [user, dispatch]);

  const handleFileInputChange = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      Object.assign(file, { base64: reader.result });
      const imgUrls = await uploadFileAsync(file);
      if (!imgUrls.thumb) alert('Error uploading image');
      const shaData = await getSha256(file);

      setImageUrls(imgUrls);
      setSha256(shaData);
    };
  };

  const handleSubmit = useCallback(
    async values => {
      setLoading(true);
      const { tokenName, description, assetType, fungibleQuantity, selectCollection } = values;
      const _attributes =
        attributes[0]?.value === 'Value'
          ? []
          : attributes.map(item => ({ trait_type: item.trait_type, value: item.value }));

      const contractObject = collections.filter(contract => selectCollection === contract.address);

      const item = {
        isNew: true,
        name: tokenName,
        description,
        sha256,
        contract: selectCollection,
        attributes: _attributes,
        blockchain: contractObject[0].blockchain,
        ownerId: user.uid,
        images: [{ full: imageUrls.full, thumb: imageUrls.thumb }],
      };

      if (assetType === 'no-nft') {
        item.quantity = Number(fungibleQuantity);
      }

      try {
        setLoading(true);
        // eslint-disable-next-line no-undef
        const result = await createNFTFromCredit({ item });
        if (result === 'no-credits') {
          setCredits(0);
        } else if (result === true) {
          window.location.href = `${window.location.href}-success`;
        } else if (result.error) {
          alert(result.error.message);
          setLoading(false);
        }
      } catch (error) {
        console.log('error: ', error);
        setLoading(false);
      }
    },
    [imageUrls, sha256, user, attributes, collections],
  );

  useEffect(() => {
    dispatch(setActiveModal('none'));
  }, [user, dispatch]);

  useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      let _contractData = [];

      // Fetch user credits
      if (user) {
        _contractData = await listCollections();

        setCollections(_contractData.map(item => ({ label: item.name, value: item.address, ...item })));

        const creditData = await fetchUserCredits();
        setCredits(creditData.nftCredits);
        setLoading(false);
      }
    };

    initializeData();
  }, [user]);

  const checkPopup = () => {
    if (!user || collections.length === 0) {
      setShowCreateCollection(true);
    } else {
      setShowCreateCollection(false);
    }
  };

  useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      checkPopup();
    }, 10000);
  }, [user, collections]);

  const validationSchema = Yup.object().shape({
    selectCollection: Yup.string().required('Collection Type is required'),
    tokenName: Yup.string().required('Token name is required'),
    assetType: Yup.string().required('Asset Type is required'),
    description: Yup.string().required('Description is required'),
    fungibleQuantity: Yup.number().typeError('The quantity must be a whole number'),
    image: Yup.string().required('Image is required'),
  });

  return (
    <SectionLayout>
      {loading ? (
        <Loading
          type={'bubbles'}
          color={'#2bc8c5'}
          height={'80px'}
          width={'80px'}
          containerHeight={'40vh'}
          containerWidth={'100%'}
          message="Loading..."
        />
      ) : (
        <Container>
          <div className={classess.mintNft}>
            <div className={classess.mintNftCol}>
              <div className={classess.mintNftHeader}>
                <h4 className={classess.heading}>Mint NFT</h4>
                <p className={classess.authLink}>
                  <span>{user ? 'Logged in' : 'Logged out'}</span>
                  <Link href="#">
                    <span onClick={toggleLogin} className={classess.logoutLink}>
                      {user ? '(Logout)' : '(Login)'}
                    </span>
                  </Link>
                </p>
              </div>

              <Formik
                initialValues={{
                  selectCollection: collections[0]?.value || '',
                  tokenName: '',
                  assetType: 'nft',
                  description: '',
                  image: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form>
                    <Box mb={4}>
                      <p className={classess.helperText}>
                        {' '}
                        This interface creates Polygon-blockchain NFTs compatible with most crypto wallets. After the
                        minting is complete you may transfer, sell, or create a free claim for your NFT(s).
                      </p>
                    </Box>

                    <Box mb={4}>
                      <Select
                        label="Collection"
                        name="selectCollection"
                        options={collections}
                        touched={touched.selectCollection}
                        error={errors.selectCollection}
                        value={values.selectCollection}
                        onChange={value => setFieldValue('selectCollection', value)}
                      />
                    </Box>

                    <Input
                      name="tokenName"
                      label="Token Name"
                      touched={touched.tokenName}
                      error={errors.tokenName}
                      value={values.tokenName}
                      type="text"
                    />

                    <UploadBtn
                      urls={imageUrls}
                      onChange={file => {
                        handleFileInputChange(file);
                        setFieldValue('image', 'uploaded');
                      }}
                      error={touched.image ? errors.image : ''}
                    />

                    <Box mt={8}>
                      <p className={classess.assetsType}>What type of asset is this?</p>
                      <CSelect
                        name="assetType"
                        options={ASSET_OPTIONS}
                        touched={touched.assetType}
                        error={errors.assetType}
                        value={values.assetType}
                        onChange={value => setFieldValue('assetType', value)}
                      />
                      <p className={classess.tokenInputHelperText}>
                        NFT's (Non-Fungible Tokens) represent things that are wholly owned by one person like art or
                        merchandise. If you select multiple here you will still only be charged for 1 NFT credit even if
                        you make 10,000 editions.
                      </p>
                    </Box>

                    {values.assetType === 'no-nft' && (
                      <Box mt={6}>
                        <Input
                          name="fungibleQuantity"
                          label="Fungible Quantity"
                          touched={touched.fungibleQuantity}
                          error={errors.fungibleQuantity}
                          value={values.fungibleQuantity}
                          type="number"
                        />
                      </Box>
                    )}

                    <Box mt={6}>
                      <TextArea
                        name="description"
                        label="Description"
                        touched={touched.description}
                        error={errors.description}
                        value={values.description}
                        onChange={value => setFieldValue('description', value)}
                        type="textarea"
                        rows={5}
                      />
                      <p className={classess.tokenInputHelperText}>
                        <a href="https://www.markdownguide.org/cheat-sheet/" target="new">
                          Markdown syntax is supported.
                        </a>
                      </p>
                    </Box>

                    <div className={classess.mintNftSection}>
                      <Button background btnLabel="Mint" disabled={isMintDisabled()} />

                      <div className={classess.mintFeeContainer}>
                        <div className={classess.mintFeeContent}>
                          <span className={classess.mintFeeLable}>Credits left</span>
                          <span className={classess.minFee}>{credits}</span>
                        </div>
                        <div>
                          <Link to="/buy-credits" className={classess.buyMoreLink}>
                            Buy more{' '}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <div className={classess.mintNftCol}>
              <h4 className={classess.heading}>Attributes</h4>
              <Box mb={4}>
                <p className={classess.helperText}>
                  These are optional key pair values that can be useful for describing common features such as "hair"
                  and "blue" or "Year" "1985".
                </p>
              </Box>
              <Grid container spacing={2}>
                {attributes.map(item => {
                  return (
                    <Fragment key={item.id}>
                      <Grid item xs={6}>
                        <OutlinedInput
                          fullWidth
                          variant="outlined"
                          placeholder="Property"
                          onChange={e => handleTraitTypeChange(e.target.value, item)}
                          endAdornment={
                            <InputAdornment style={{ cursor: 'pointer' }} position="end">
                              <IconButton onClick={() => removeAttributes(item)}>
                                <img src={Cros} alt="not found" />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <OutlinedInput
                          fullWidth
                          variant="outlined"
                          placeholder="Value"
                          onChange={e => handleValueChange(e.target.value, item)}
                          endAdornment={
                            <InputAdornment style={{ cursor: 'pointer' }} position="end">
                              <IconButton onClick={() => removeAttributes(item)}>
                                <img src={Cros} alt="not found" />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Grid>
                    </Fragment>
                  );
                })}
              </Grid>

              <div>
                <button className={classess.addBtn} onClick={() => addAttributes()}>
                  Add More
                </button>
              </div>
            </div>
          </div>
        </Container>
      )}

      <Dialog classes={{ paper: classess.dialogContainer }} open={showCreateCollection}>
        <Box p={4} className={classess.createCollectionTitle}>
          You need a collection for your NFTs. If you made a collection then it is still being processed. Please refresh
          this page in 2 minutes or <a href="/create-collection">create your first collection</a>.
        </Box>
        <Box px={4} pb={3} display="flex" justifyContent="flex-end">
          <Button background btnLabel="Create Collection" handleClick={() => history.push('/create-collection')} />
        </Box>
      </Dialog>

      <Dialog classes={{ paper: classess.dialogContainer }} open={credits === 0}>
        <Box p={4} className={classess.createCollectionTitle}>
          Insufficient Credits
        </Box>
        <Box px={4} pb={3} display="flex" justifyContent="flex-end">
          <Button background btnLabel="Buy more credits" handleClick={() => history.push('/buy-credits')} />
        </Box>
      </Dialog>
    </SectionLayout>
  );
};

export default Mint;
