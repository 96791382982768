import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { AUTH_MODALS } from 'Constants/modals';
import { setAuthModal } from 'Store/actions';
import * as actions from 'Store/actions';
import GlobalButton from 'Shared/Button';
import Input from 'Shared/Input';

import { useStyles } from './style';

const ResetPassword = ({ setIsLoginPage, setIsResetPass, passwordReset, onClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [emailRequested, setEmailRequested] = useState();

  const resetScheme = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Provide your login email'),
  });

  const openLoginModal = () => {
    onClose();
    dispatch(setAuthModal(AUTH_MODALS.LOGIN));
  };

  return (
    <div className={classes.resetPassContainer}>
      <div>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={resetScheme}
          onSubmit={async (values, { resetForm }) => {
            const email = values.email;
            try {
              await passwordReset({
                email,
              });
              resetForm();
              dispatch(setAuthModal(AUTH_MODALS.LOGIN));
            } catch (err) {
              console.warn('Reset server error: ', err);
              alert('Reset connection error');
              return;
            }
          }}
        >
          {({ errors, touched, values }) => (
            <Form>
              <p className={classes.resetPassLabel}>
                If you want to change your password, we'll send you a link to reset your password.
              </p>
              <Input name="email" touched={touched.email} error={errors.email} value={values.email} />
              <div className={classes.resetBtnContainer}>
                <GlobalButton btnLabel="Reset password" background />
                {emailRequested && (
                  <span className={classes.successMsg}>
                    If there is an account associated with {emailRequested} you will receive reset instructions
                  </span>
                )}

                <div className={classes.resetHelperText}>
                  <button type="button" className={classes.link} onClick={openLoginModal}>
                    Login
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

// export default ResetPassword;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      passwordReset: actions.passwordReset,
      setActiveModal: actions.setActiveModal,
    },
    dispatch,
  );
}

export default withRouter(connect(null, mapDispatchToProps)(ResetPassword));
