import React from 'react';
import { closeActiveModal } from 'Store/actions';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import FormTextField from 'Components/FormTextField';
import ModalContent from '../ModalContent';
import history from '../../history';

function FoundItem() {
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {},
  });

  const onSubmit = async data => {
    const { tagCode } = data;
    dispatch(closeActiveModal());
    history.push(`/i/${tagCode}`);
  };

  const getHelperText = () => {
    if (errors.tagCode) {
      return errors.tagCode.message;
    }

    return "Please enter the 6 digit code. It's case sensitive.";
  };

  return (
    <ModalContent
      title="Find NFT"
      buttonText="Submit"
      onFormSubmit={handleSubmit(onSubmit)}
      formContents={
        <FormTextField
          fullWidth
          label="Tag Code"
          name="tagCode"
          margin="normal"
          inputProps={{
            maxLength: 6,
          }}
          required
          error={!!errors.tagCode}
          helperText={getHelperText()}
          inputRef={register({
            required: 'Please enter 6 digit code',
            pattern: {
              value: /^[A-Z0-9]{6}$/i,
              message: 'Code must be 6 digits',
            },
          })}
          placeholder="Enter Tag Code"
        />
      }
    />
  );
}

export default withRouter(FoundItem);
