import React, { useState } from 'react';
import { Box, Typography, Container, Grid } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';

import PageLayout from 'Layout/Layout';
import SectionLayout from 'Layout/SectionLayout';
import * as actions from 'Store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import config from 'Config/firebase';
import FormContainer from 'Components/FormInputs/FormContainer';
import TextField from 'Components/FormInputs/TextField';
import Button from 'Shared/Button';
import axios from 'Services/axios';
import * as Yup from 'yup';

const styles = theme => ({
  header: {
    marginTop: 30,
  },
});

function ContactDeploy({ setActiveModal, classes }) {
  const [mailSent, setMailSent] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const onSubmit = async data => {
    try {
      const payload = {
        ...data,
      };

      const validationResult = await axios.post('/checkRecaptcha', { captchaToken });
      if (validationResult?.data?.status !== 'success') {
        setCaptchaToken(null);
        return;
      }

      fetch(`${config.functionsURL}/contactAboutDeployingOnchain`, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
    } catch (err) {
      console.warn('Contact server error: ', err);
      alert('Contact connection error');
      return;
    }

    setMailSent(true);
  };

  const validationSchema = Yup.object().shape({
    website: Yup.string().nullable().required('This field is required'),
    email: Yup.string().nullable().required('This field is required').email('Provide a valid email address'),
    message: Yup.string().nullable().required('This field is required').min(3, 'Message too short'),
  });

  const initialValues = {
    subject: 'business',
    email: '',
    message: '',
  };

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container component="main" maxWidth="sm">
          <Box mb={4}>
            <Typography component="h2" variant="h2" className={classes.header}>
              Join the revolution! Deploy a website on-chain!{' '}
            </Typography>
            <Typography component="h4" variant="h4" className={classes.header}>
              {' '}
              Low cost. <br />
              Lasts forever. <br />
              No more hosting fees. <br />
              We'll respond within 24 hours. <br />
              (Website must be legal in your jurisdiction)
            </Typography>
          </Box>

          {!mailSent ? (
            <FormContainer validation={validationSchema} defaultValues={initialValues} onSuccess={onSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField style={{ width: '100% ' }} name="email" label="Email" placeholder="Enter Email" />
                </Grid>

                <Grid item xs={12}>
                  <TextField style={{ width: '100% ' }} name="website" label="Website Address" placeholder="" />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    style={{ width: '100% ' }}
                    name="message"
                    label="Message"
                    multiline
                    placeholder=""
                    minRows={2}
                    maxRows={80}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ReCAPTCHA
                    sitekey={config.captchaSiteKey}
                    onChange={token => setCaptchaToken(token)}
                    onExpired={() => setCaptchaToken(null)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button background type="submit" disabled={!captchaToken} btnLabel="Submit" />
                </Grid>
              </Grid>
            </FormContainer>
          ) : (
            <div className={classes.header}>We will respond as soon as possible!</div>
          )}
        </Container>
      </SectionLayout>
    </PageLayout>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitLoginWithFireBase: actions.submitLoginWithFireBase,
      setActiveModal: actions.setActiveModal,
    },
    dispatch,
  );
}

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(ContactDeploy)));
