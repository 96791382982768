import React, { useState, useEffect } from 'react';
import { isNil } from 'lodash';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getEthSendFee, sendEth } from '../../Services/api';
import { Box, Grid, Dialog, DialogTitle, DialogContent, CircularProgress, Button, Typography } from '@material-ui/core';
import { setAuthModal, closeActiveModal } from 'Store/actions';
import useCheckCoinBalance from 'Hooks/useCheckCoinBalance';
import { formatWithDecimal } from 'Helpers/tools';
import FormContainer from 'Components/FormInputs/FormContainer';
import TextField from 'Components/FormInputs/TextField';
import * as Yup from 'yup';
import history from '../../history';

function SendEthereum() {
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [sendFeeInEth, setSendFeeInEth] = useState(null);
  const [sendFeeInUsd, setSendFeeInUsd] = useState(null);
  const { balance: ethBalance, isLoading: isLoadingBalance } = useCheckCoinBalance();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSendFee = async () => {
      const fees = await getEthSendFee();

      const { totalFeeInEth, totalFeeInUsd } = fees.data;
      setSendFeeInEth(totalFeeInEth);
      setSendFeeInUsd(totalFeeInUsd.toFixed(2));
    };

    fetchSendFee();
  }, []);

  const sendEthereum = async data => {
    setSubmitLoading(true);
    await sendEth({ to: data.address, amount: data.ethAmountToSend });
    setTimeout(() => {
      history.push(`/history`);
      handleClose();
    }, 3000);
  };

  const validationSchema = Yup.object().shape({
    address: Yup.string()
      .nullable()
      .required('Ethereum address is required')
      .matches(/^0x[a-fA-F0-9]{40}$/, 'Provide a valid ethereum address'),
    ethAmountToSend: Yup.string()
      .nullable()
      .required('The amount is required')
      .matches(/^-?[0-9]\d*\.?\d*$/, 'Provide a valid number')
      .test('available', 'This is more Ethereum than you have available.', value => {
        const balanceMinusFee = formatWithDecimal(ethBalance) - sendFeeInEth;
        return parseFloat(value) < balanceMinusFee;
      }),
  });

  const initialValues = {
    address: '',
    ethAmountToSend: '',
  };

  const handleClose = () => {
    dispatch(closeActiveModal(null));
  };

  // TODO: convert this into the standard modal form with container instead of dialog here
  return (
    <Dialog fullWidth maxWidth="md" open={true} onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2">Send Ethereum</Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box pb={2} display="flex" alignItems="center">
          <Box mr={1}>
            <b>Available balance:</b>
          </Box>

          {!isNil(ethBalance) && !isLoadingBalance ? (
            <Box>{formatWithDecimal(ethBalance)} ETH</Box>
          ) : (
            <CircularProgress size={16} />
          )}
        </Box>

        <FormContainer
          validation={validationSchema}
          defaultValues={initialValues}
          onSuccess={sendEthereum}
          mode="onChange"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Ethereum address"
                name="address"
                variant="outlined"
                placeholder="Enter ethereum address"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Amount (ETH)"
                name="ethAmountToSend"
                variant="outlined"
                placeholder="Enter amount (ETH)"
              />
            </Grid>

            <Grid item xs={12}>
              <Box mt={3} display="flex" alignItems="center">
                <Box mr={1}>
                  <b>Cost to transfer:</b>
                </Box>

                {sendFeeInEth && sendFeeInUsd ? (
                  <Box>{`${sendFeeInEth.toFixed(6)} ETH ($${sendFeeInUsd})`}</Box>
                ) : (
                  <CircularProgress size={16} />
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <small>Transfer fee is based on a set gas amount and varying Ethereum rates.</small>
            </Grid>

            <Grid item xs={12}>
              <Box py={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!ethBalance || isSubmitLoading || isLoadingBalance}
                >
                  Confirm Send
                </Button>
              </Box>
            </Grid>
          </Grid>
        </FormContainer>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(SendEthereum);
