import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import Container from 'Components/Container';
import { listSubscriptions } from 'Services/api';
import Button from '../../Shared/Button';
import { useStyles } from './style';

const Pricing = ({ title, content }) => {
  const classes = useStyles();
  const [subscriptions, setSubscriptions] = useState([
    {
      maxTransfers: 10,
      stripeProductId: 'prod_LX9F7FhbBBe2FG',
      maxMints: 10,
      priceId: 'price_1M0yXBEKiTJNd6eQcDIPjnb7',
      maxContracts: 5,
      price: 0,
      type: 'subscription',
      name: 'Free',
      maxWallets: 5,
      id: 'subscription1',
    },
  ]);

  const history = useHistory();

  useEffect(() => {
    const getSubscriptions = async () => {
      const data = await listSubscriptions();

      setSubscriptions(data);
    };

    getSubscriptions();
  }, []);

  return (
    <Container>
      <Grid container className={classes.container} spacing={4}>
        {subscriptions.map((item, index) => (
          <Grid key={index} item xs={12} md={6} lg={3}>
            <div
              className={
                item.name === 'Standard' ? classNames(classes.priceSection, classes.proSection) : classes.priceSection
              }
            >
              <div className={item.name === 'Standard' ? classes.proTopSection : classes.pricingTopSection}>
                <h3 className={classes.priceTitle}>{item.name}</h3>
                <p className={classes.price}>
                  <span style={{ marginRight: 12 }}>$</span>
                  <span className={classes.priceValue}>{item.price}</span>
                </p>
              </div>
              <div>
                <ul className={classes.priceList}>
                  <li className={classes.priceListItem}>Unlimited API Calls</li>
                  <li className={classes.priceListItem}>Unlimted rq/s</li>
                  <li className={classes.priceListItem}>No crypto needed</li>
                  <li className={classes.priceListItem}>Free IPFS Hosting</li>
                  <li className={classes.priceListItem}>{item.maxContracts || 0} Contracts per month</li>
                  <li className={classes.priceListItem}>{item.maxMints} NFTs per month</li>
                  <li className={classes.priceListItem}>{item.maxTransfers} Transfers per month</li>
                  <li className={classes.priceListItem}>{item.maxWallets} Custodial user wallets created per month</li>
                </ul>
              </div>
              <div className={classes.btnWrapper}>
                <Button
                  background
                  btnLabel="Get Started"
                  data-cy={`${item.name.toLowerCase()}-subscription-button`}
                  handleClick={() => history.push(`/api-signup?plan=${item.id}`)}
                />
              </div>
            </div>
          </Grid>
        ))}

        <Grid key="enterprise" item xs={12} md={6} lg={3}>
          <div className={classes.priceSection}>
            <div className={classes.pricingTopSection}>
              <h3 className={classes.priceTitle}>Enterprise</h3>
              <p className={classes.price}>
                <span className={classes.priceValue}>Custom</span>
              </p>
              <p className={classes.priceDesc}>Volume discounts, enterprise support &amp; SLAs</p>
            </div>
            <div>
              <ul className={classes.priceList}>
                <li className={classes.priceListItem}>Mint any number of NFTs</li>
                <li className={classes.priceListItem}>Deep discounts</li>
                <li className={classes.priceListItem}>Dedicated support</li>
                <li className={classes.priceListItem}>Ethereum Available on Request</li>
                <li className={classes.priceListItem}>Custom rate limits</li>
              </ul>
            </div>
            <div className={classes.btnWrapper}>
              <button className={classes.btn} onClick={() => history.push('/contact')}>
                Contact us
              </button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Pricing;
